import React from 'react';

const IconLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90.371"
    height="106.016"
    viewBox="0 0 90.371 106.016">
    <g id="Group_1" data-name="Group 1" transform="translate(-547.5 -482.973)">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M666,508.581c.04,33.425,0,47.655,0,47.655L710.631,584.4l44.74-28.162V508.581l-44.74-29.012Z"
        transform="translate(-118 4)"
        fill="#0a192f"
        stroke="#707070"
        stroke-width="1"
      />
      <text
        id="F"
        transform="translate(579 506)"
        fill="#64ffda"
        font-size="45"
        font-family="HelveticaNeue-Bold, Helvetica Neue"
        font-weight="700">
        <tspan x="0" y="44">
          F
        </tspan>
      </text>
    </g>
  </svg>
);

export default IconLogo;
